import { BrowserRouter as Router } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import "./App.css";
import OrganizationFetcher from "./components/OrganizationFetcher";
import AppRouter from "./routes";
import ScrollToTop from "./components/ScrollToTop";

// apollo client
const client = new ApolloClient({
    uri: `${process.env.REACT_APP_BACKEND_URL}/graphql`,
    cache: new InMemoryCache(),
});

function App() {
    return (
        <Router>
            <ScrollToTop />
            <ApolloProvider client={client}>
                <OrganizationFetcher />
                <div className="App">
                    <AppHeader />
                    <AppRouter />
                    <AppFooter />
                </div>
            </ApolloProvider>
        </Router>
    );
}
export default App;
