import React, { useState, useEffect } from "react";
import { Menu, Dropdown } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import "./MenuBar.css";
import { Link } from "react-router-dom";

export default function MenuBar({ data }) {
    const pagesToHardNavigate = [
        "/regions",
        "/timetables",
        "/trip-planner",
        "/schools",
        "/ogdens_orana",
        "/ogdens_western",
        "/ogdens_mudgee",
        "/ogdens_condobolin",
    ];
    const [items, setItems] = useState([]);
    const [current, setCurrent] = useState("home");
    const [menuMode, setMenuMode] = useState();
    const [menuVisible, setMenuVisible] = useState(false);

    const onClick = (e) => {
        setCurrent(e.key);
    };

    const showMainMenu = () => {
        return <Menu onClick={onClick} selectedKeys={[current]} mode="horizontal" items={items} />;
    };

    const handleNavigation = (url) => {
        if (pagesToHardNavigate.includes(url)) {
            window.location.href = url;
        }
    };

    useEffect(() => {
        if (data) {
            const menuItems = data.wbMenus?.data?.[0]?.attributes?.menu;
            const items = menuItems?.map((item) => {
                const isHardNavigate = pagesToHardNavigate.includes(item.url);

                const createLink = (url, name) => {
                    return isHardNavigate ? (
                        <a href={url} onClick={(e) => { e.preventDefault(); handleNavigation(url); }}>
                            {name}
                        </a>
                    ) : (
                        <Link to={url} target={url.startsWith("/") ? "_self" : "_blank"}>
                            {name}
                        </Link>
                    );
                };

                if (item.__typename === "ComponentWbMenusMenu") {
                    return {
                        label: item.url ? createLink(item.url, item.name) : <Link>{item.name}</Link>,
                        key: item.url,
                    };
                } else if (item.__typename === "ComponentWbMenusSubmenu") {
                    return {
                        label: item.url ? createLink(item.url, item.name) : <Link>{item.name}</Link>,
                        key: item.url,
                        ...(item.menu.length > 0 && {
                            children: item.menu.map((subItem) => {
                                return {
                                    label: subItem.url ? createLink(subItem.url, subItem.name) : <Link>{subItem.name}</Link>,
                                    key: subItem.url,
                                    type: subItem.menu.length > 0 ? "group" : "item",
                                    ...(subItem.menu.length > 0 && {
                                        children: subItem.menu.map((subSubItem) => {
                                            return {
                                                label: subSubItem.url ? createLink(subSubItem.url, subSubItem.name) : subSubItem.name,
                                                key: subSubItem.url,
                                            };
                                        }),
                                    }),
                                };
                            }),
                        }),
                    };
                }
            });
            setItems(items);
        }
    }, [data]);

    useEffect(() => {
        const checkWindowSize = () => {
            if (window.innerWidth > 992) {
                setMenuMode("horizontal");
            } else {
                setMenuMode("inline");
            }
        };

        window.addEventListener("resize", checkWindowSize);
        checkWindowSize();

        return () => window.removeEventListener("resize", checkWindowSize);
    }, []);

    return (
        <div className="MenuBar">
            {menuMode === "inline" ? (
                <Dropdown menu={{ items }} open={menuVisible} trigger={["click"]} overlayClassName="AppMenuDrop">
                    <button
                        className="ant-dropdown-link"
                        onClick={(e) => {
                            e.preventDefault();
                            setMenuVisible(!menuVisible);
                        }}
                    >
                        <MenuOutlined />
                    </button>
                </Dropdown>
            ) : (
                showMainMenu()
            )}
        </div>
    );
}