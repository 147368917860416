import React, { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import StorageService from "../utils/storage";

const OrganizationFetcher = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch organization data using the hostname
        const hostname = window.location.host;
        axios
            .get(`${process.env.REACT_APP_BACKEND_URL}/api/organisations?filters[hostnames][url][$eq]=${hostname}&filters[hostnames][type][$eq]=Website&populate[hostnames]=*&populate[logo]=*&populate[favicon]=*&populate[WebsiteBuilder]=*`)
            .then((response) => {
                // Handle successful response, maybe store organization data in state or context
                if (response.data.data.length === 0) {
                    navigate("/not-found");
                }
                
                const organization = response.data.data[0];
                const orgId = organization.id;
                const busableId = organization.attributes.orgId;
                const orgSlug = organization.attributes.slug;
                const gaTag = organization.attributes.gaTag;

                StorageService.setItem("organisation", organization.attributes)
                StorageService.setItem("organisationId", orgId);
                StorageService.setItem("organisationBusableId", busableId);
                StorageService.setItem("organisationSlug", orgSlug);
                StorageService.setItem("logo", organization.attributes.logo.data?.attributes?.url);
                StorageService.setItem("favicon", organization.attributes.favicon.data?.attributes?.url);
                StorageService.setItem("GATag", gaTag);
            })
            .catch((error) => {
                navigate("/not-found");
            });
    }, [navigate]);

    // Render loading indicator or nothing, as this component is just for fetching data
    return null;
};

export default OrganizationFetcher;
